<template>
  <div class="px-10 text-center">
    <span class="mt-10 d-block font-20 black--text font-500">
      {{ $t("clickToCopy") }}</span
    >

    <!-- {{$t('Click to copy the code below')}}</span -->
    <div
      class="view-code text-center d-flex justify-center my-10"
      ref="code"
      @click.prevent="copyHandler"
    >
      <!-- <div class="primary--text" v-if="type == 'shipment'">
        {{ item.code_delivery }}
      </div>
      <div class="primary--text" v-if="type == 'type'">
        {{ item.code_receive }}
      </div> -->
      {{ type == "trip" ? item.code_receive : item.code_delivery }}
    </div>
    <div class="mb-10">
      <v-btn class="c-btn mx-1" color="grey" height="40" @click="$emit('close')"
        ><span class="white--text font-300 font-20 d-inline-block px-7">{{
          $t("close")
        }}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "shipment",
    },
  },
  data: () => ({}),
  methods: {
    async copyHandler() {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = this.item.code_delivery;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$store.dispatch("showSnack", {
        text: "Code has been copied to your clipboard",
        color: "success",
      });
    },
  },
};
</script>

<style lang="scss">
.view-code {
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid $grey;
  cursor: pointer;
  transition: 0.3s;

  span {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 14px;
  }

  &:hover {
    background: $lightbluebg;
  }
}
</style>
