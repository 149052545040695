<template>
  <div class="max-width">
    <!-- items -->
    <v-row v-if="!loading && item.shipments.length > 0">
      <v-col cols="12" md="4" v-for="(items, i) in item.shipments" :key="i">
        <product-card :item="items">
          <template #slotBtnRequest>
            <!-- accept btn -->
            <v-btn
              class="c-btn"
              color="grey"
              elevation="0"
              height="35"
              v-if="items.status == 'waiting'"
              ><span class="white--text font-20 font-300 px-8 d-inline-block">{{
                $t("waiting")
              }}</span></v-btn
            >
            <!-- accept btn -->
            <v-btn
              class="c-btn"
              color="primary"
              elevation="0"
              height="35"
              v-if="items.status == 'pending'"
              @click="acceptHandler(items.id, items.order_id);refactiv= !refactiv"
              ><span class="white--text font-20 font-300 px-8 d-inline-block">{{
                $t("accept")
              }}</span></v-btn
            >
            <!-- enter code btn -->
            <v-btn
              class="c-btn"
              color="primary"
              elevation="0"
              height="35"
              v-if="items.status == 'in progress'"
              @click="enterCodeHandler(items); refactiv= !refactiv"
              ><span class="white--text font-20 font-300 px-8 d-inline-block">{{
                $t("enterCode")
              }}</span></v-btn
            >
            <!-- show code btn -->
            <v-btn
              class="c-btn"
              color="primary"
              elevation="0"
              height="35"
              v-if="items.status == 'payment'"
              @click="showCodeHandler(items); refactiv= !refactiv"
              ><span class="white--text font-20 font-300 px-8 d-inline-block">{{
                $t("showCode")
              }}</span></v-btn
            >
            <div v-if="items.status=='aggrement'" style="height: 30px; background-color: #22c55e; margin-bottom: -4px;" > 
              <h4 class="font-22 white--text pt-1">{{ $t("agreementReached") }}</h4>
            </div>
            <div v-if="items.status=='completed'" style="height: 30px; background-color: #22c55e;margin-bottom: -4px;" > 
              <h4 class="font-22 white--text pt-1"> {{$t('completed')}}</h4>
            </div>
            
          </template>
        </product-card>
      </v-col>
    </v-row>

    <!-- if empty -->
    <div class="text-center mt-8">
      <no-items v-if="!loading && item.shipments.length == 0"></no-items>
    </div>

    <!-- if loading -->
    <div v-if="loading">
      <v-row>
        <v-col cols="12" md="4" v-for="n in 3" :key="n">
          <skeleton-card />
        </v-col>
      </v-row>
    </div>
    <!-- accept dialog -->
    <v-dialog v-model="acceptDialog" max-width="400" content-class="white">
      <wait-code-dialog
        :item="item"
        @close="acceptDialog = false"
      ></wait-code-dialog>
    </v-dialog>

    <!-- accept dialog -->
    <v-dialog v-model="enterCodeDialog" max-width="400" content-class="white">
      <code-shipment
        :item="selectedItem"
        type="trip"
        @close="enterCodeDialog = false"
        :tripID="item.id"
      ></code-shipment>
    </v-dialog>

    <!-- accept dialog -->
    <v-dialog v-model="showCodeDialog" max-width="400" content-class="white">
      <view-code
        :item="selectedItem"
        type="trip"
        @close="showCodeDialog = false"
      ></view-code>
    </v-dialog>
  </div>
</template>

<script>
import ProductCard from "@/components/core/ProductCard.vue";
import WaitCodeDialog from "../../../../../components/dialogs/WaitCode.vue";
import CodeShipment from "../../../../../components/dialogs/CodeShipment.vue";
import ViewCode from "../../../../../components/dialogs/ViewCode.vue";
export default {
  components: { ProductCard, WaitCodeDialog, CodeShipment, ViewCode },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
  },
  data: () => ({
    acceptDialog: false,
    enterCodeDialog: false,
    showCodeDialog: false,
    selectedItem: {},
  }),
  methods: {
    async acceptHandler(id, order_id) {
      let formData = new FormData();
      formData.append("trip_id", this.$route.params.id);
      formData.append("package_id", id);
      formData.append("order_id", order_id);

      await this.$axios
        .post("/acceptShipmentbyBringer", formData)
        .then((data) => {
          if (data.success) {
            this.$store.dispatch("showSnack", {
              text: data.message,
              color: "success",
            });
            this.acceptDialog = true;
          } else {
            this.$store.dispatch("showSnack", {
              text: data.message,
              color: "error",
            });
          }
        });
    },
    enterCodeHandler(item) {
      console.log(item);
      this.selectedItem = item;
      this.enterCodeDialog = true;
    },
    showCodeHandler(item) {
      console.log(item);
      this.selectedItem =item;
      this.showCodeDialog = true;
    },
  },
  computed: {
    refactiv: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style></style>
