<template>
  <div class="code-dialog" dir="ltr">
    <div class="px-10 text-center">
      <span v-if="type == 'trip'" class="mt-10 d-block font-25 black--text font-500">
        {{$t('codeSenyShipment')}} </span
      >
        <!-- {{$t('Enter the code you want to send to shipment')}} </span -->
      <span v-if="type == 'shipment'" class="mt-10 d-block font-25 black--text font-500">
        {{$t('codeSentTraveler')}}</span
      >
        <!-- Enter the code sent to you by the traveler</span -->
      <!-- <span v-if="type == 'shipment'" class="d-block font-30 black--text font-500">
        {{$t('code')}}: {{ item.code_receive }}</span
      > -->
      <div class="text-center d-flex justify-center my-10 code-dialog__inputs">
        <CodeInput
          :loading="false"
          class="input"
          :fields="6"
          v-on:complete="onComplete"
        />
      </div>
      <div class="mb-10">
        <v-btn
          class="c-btn mx-1"
          color="grey"
          height="40"
          @click="$emit('close')"
          v-on:complete="onComplete"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >{{$t('no')}}</span
          ></v-btn
        >
        <v-btn
          class="c-btn mx-1"
          color="primary"
          height="40"
          @click="submitHandler"
          ><span class="white--text font-300 font-20 d-inline-block px-7"
            >{{$t('sendCode')}}</span
          ></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'shipment'
    },
    tripID: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    code: "",
  }),
  methods: {
    onComplete(value) {
      this.code = value;
    },
    async submitHandler() {
      if (this.code.length < 6) {
        this.$store.dispatch("showSnack", {
          text: "code must be 6 digit",
          color: "error",
        });
        return;
      }
      let url  = 'enterQrCodeForRecieveShipment'
      let formData = new FormData();
      formData.append("shipment_id", this.item.id);
      if(this.type == 'trip'){
        formData.append("trip_id", this.tripID);
        formData.append("code_delivery", this.item.code_delivery);
        url='enterQrCodeForDeliveryShipment'
      }else{
        formData.append("code_receiver", this.item.code_receive);

      }
      let { data } = await this.$axios.post(
        url,
        formData
      );
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "success",
        });
        this.$emit("codeAdded");
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss">
.code-dialog {
  input {
    width: 40px !important;
    height: 40px !important;
  }
}
</style>
